import React from 'react';

const PatreonButton = () => {
    const patreonLink = 'https://www.patreon.com/AlbumAnimator'; // Replace with your actual Patreon page link

    return (
        <button
            onClick={() => window.open(patreonLink, '_blank')}
            className="patreonButton"
        >
            Support Us on Patreon
        </button>
    );
};

export default PatreonButton;
