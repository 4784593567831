import React from 'react';

const DiscordButton = () => {
    const inviteLink = 'https://discord.gg/yW4Kbxpw3X'; // Replace with your actual Discord invite link

    return (
        <button
            onClick={() => window.open(inviteLink, '_blank')}
            className="discordButton"
        >
            Join Our Discord
        </button>
    );
};

export default DiscordButton;
