import React from 'react';
import KofiLogo from '../assets/images/KofiLogo.webp'; // Adjust path if needed

const KofiButton = ({ onAfterClick }) => {
    const inviteLink = 'https://ko-fi.com/albumanimator';

    return (
        <button
            onClick={() => {
                window.open(inviteLink, '_blank');
                if (onAfterClick) {
                    onAfterClick(); // Trigger additional logic after clicking
                }
            }}
            className="kofiButton"
        >
            <img
                src={KofiLogo}
                alt="Ko-Fi Logo"
                className="kofi-logo"
            />
            Buy Me a Coffee!
        </button>
    );
};

export default KofiButton;
