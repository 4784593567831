import { Link } from "react-router-dom";
import { useRendering } from './RenderingContext'; // Adjust the import path as necessary
import CustomLink from './CustomLink'; // Adjust the import path as necessary
import Lab1 from "../icons/Lab1";
import logo from '../assets/images/Logo.png'; // Import your logo


const Navbar = ({ user, thereAreVideos, setThereAreVideos }) => {

    const { isRendering } = useRendering();





    const logout = (e) => {
        e.preventDefault();

        if (isRendering) {
            const userConfirmed = window.confirm('Rendering is in progress. Do you want to leave this page?');
            if (!userConfirmed) {
                return; // Ensure no navigation if user cancels
            }
            //} else if (thereAreVideos) {
        } else {
            const userConfirmed = window.confirm('Leave this page? Changes you made may not be saved.');
            if (!userConfirmed) {
                return; // Ensure no navigation if user cancels
            }
        }
  
        window.open("https://www.AlbumAnimator.io/auth/logout", "_self");
    };


    const handleDeleteAccount = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete your account? This action cannot be undone!");

        if (!confirmDelete) return;

        try {
            const response = await fetch("https://www.AlbumAnimator.io/credits/delete-account", {
                method: "DELETE",
                credentials: "include", // ✅ Ensures cookies/session data are sent
                headers: { "Content-Type": "application/json" },
            });

            const data = await response.json();

            if (response.ok) {
                alert(data.message);

                // ✅ Redirect to logout to clear session properly
                window.open("https://www.AlbumAnimator.io/auth/logout", "_self");
            } else {
                alert("Failed to delete account: " + (data.error || "Try again later."));
            }
        } catch (error) {
            console.error("Error:", error);
            alert("Failed to delete account. Try again later.");
        }
    };




    return (
        <div className="navbar">
            <span className="logo">
                <CustomLink className="link" to="/"
                    thereAreVideos={thereAreVideos}
                    setThereAreVideos={setThereAreVideos}
                >
                    <img src={logo} alt="Album Animator Logo"
                        style={{ width: '350px', marginRight: '10px' }}
                    />
                    {/*    <span>  Album Animator <Lab1 /></span>*/}
                </CustomLink>
            </span>{
                user ? (

                    <ul className="list">
                        <li className="listItem">
                            <img crossOrigin="anonymous" src={user.image} alt="" className="avatar" />
                        </li>
                        <li className="listItem dropdown">
                            {user.firstName}
                            <ul className="dropdownContent">
                                <li><span style={{ color: 'lightgrey', fontWeight: 'bold' }}>Email: </span> {user.email}</li>
                                <li><span style={{ color: 'lightgrey', fontWeight: 'bold' }}>Tier: </span> {user.userTier}</li>
                                <li><span style={{ color: 'lightgrey', fontWeight: 'bold' }}>Credits: </span> {user.credits}</li>

                                <li style={{ borderBottom: '1px solid lightgrey', paddingBottom: '5px', marginBottom: '5px' }}></li>

                                <li><span style={{ color: 'lightgrey', fontWeight: 'bold' }}>Total Videos: </span> {user.totalVideos}</li>
                                <li><span style={{ color: 'lightgrey', fontWeight: 'bold' }}>Total Time (min): </span> {Math.round(user.totalTimeSeconds / 60)}</li>                                
                                <li><span style={{ color: 'lightgrey', fontWeight: 'bold' }}>Total Size (MB): </span> {user.totalFileSizeMB}</li>
                                <li><span><button
                                    onClick={handleDeleteAccount}
                                    style={{
                                        color: "red",
                                        fontWeight: "bold",
                                        cursor: "pointer", // 👈 This adds the pointer effect
                                        padding: "4px",
                                        border: "1px solid red",
                                        backgroundColor: "transparent",
                                        borderRadius: "2px",
                                        transition: "background-color 0.2s ease-in-out",
                                    }}
                                    onMouseOver={(e) => e.target.style.backgroundColor = "#ffcccc"}
                                    onMouseOut={(e) => e.target.style.backgroundColor = "transparent"}
                                >
                                    Delete My Account
                                </button></span></li>  
                            </ul>
                        </li>

                        <li className="listItem" style={{ cursor: 'pointer' }}  onClick={ logout }>Logout</li>
                    </ul>
                ) : (<Link className="link" to ="login">Login</Link>)
            }
        </div>
    );
};

export default Navbar;